import React from "react";
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined" ;

export default function GoogleManager () {

    return (
        <Helmet>
            {isBrowser && (
                <script type="text/javascript">
                    {(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtag.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window, document, 'script', 'dataLayer', 'AW-10927229662')}
                </script>
            )}  
        </Helmet>
    ) 
}