import React from "react";

import './style.css';
import '../../assets/css/responsive.css'; 
import { Link } from "gatsby";
import Logo from "../../images/icon.png"


const ContactUs_Solution = () => {
    return (
        <>
            <div style={{marginTop: 65}} className="container d-flex align-items-center flex-column contactUs" >
                <div className="row">
                    <Link style={{textDecoration: 'none', borderRadius:10, backgroundColor: "rgb(140, 205, 176)", color:"white"}} to="/contact" className="btn btn-primary BrinnanLight">
                        Contattaci
                    </Link>
                </div>
                <div className="row mt-5">
                    <div className="col m-5">
                        <img src={Logo}/>
                    </div>
                </div>
                <h2><a className="text-decoration-none blank-link BrinnanLight" href="https://www.Hdemia.cloud" target="_blank">www.Hdemia.cloud</a></h2>
                
            </div>
        </>
    )
}

export default ContactUs_Solution;