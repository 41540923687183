import React from "react";
import Logo from "../../images/pc_smartphone.png"

import './style.css';

const Info = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 992) {
            setMobile(true);
        } else {
            setMobile(false);
        }
        
    }

    React.useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        handleResize();
        window.scrollTo(0, 0); 
        return () => {
            window.removeEventListener("resize", () => handleResize());
        }
    },[])
    return (
        <>
            <div className="container overflow-hidden" >
                <div className="row align-items-center mb-5">
                    <div className="col-md-12 col-lg-12 h-100 ms-3 me-3 d-flex flex-column justify-content-center text-info-solution">
                        <div className="row me-3">
                            <div className="col-lg-12 mt-5">
                                <p className="BrinnanLight" style={{ color: '#41495c' }}><span style={{color: 'rgb(17, 105, 104)'}}>Hdemia</span> &eacute; un gestionale pensato e sviluppato per le Accademie.</p>
                            </div>
                        </div>
                        <div className="row me-3">
                            <div className="col-lg-12 mt-3">
                                <p className="BrinnanLight" style={{ color: '#41495c' }}>L'obiettivo &eacute; quello di fornire <span style={{color: 'rgb(17, 105, 104)'}}>una soluzione semplice, ricca di funzionalit&aacute; e personalizzabile</span> per rispondere alle necessità della tua scuola.</p>
                            </div>
                        </div>
                        <div className="row me-4">
                            <div className="col-lg-12 mt-3">
                                <p className="BrinnanLight" style={{ color: '#41495c' }}>&Eacute; possibile accedere ad Hdemia tramite <span style={{color: 'rgb(17, 105, 104)'}}>App mobile o direttamente dal Web</span>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Info;