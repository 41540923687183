import React from "react";
import './style.css';
import flag from "../../images/smallball.png"
import { HashLink as Link } from "react-router-hash-link";

const Feature = () => {

    return (
        <>  
            
            <div className="container margin-query-solution" style={{marginBottom: 100}}>
                    <div className="row" >
                        <div className="col-md-12 col-lg-4 feature BrinnanLight">
                            <h5><img src={flag} style={{width:40, height:40, marginRight:20 }} /> Semplicit&aacute; d'uso</h5>
                            <h5 className="mt-4"><img src={flag} style={{width:40, height:40, marginRight:20}}/> Lezioni e Video-Lezioni</h5>
                        </div>
                        <div className="col-md-12 col-lg-4 feature BrinnanLight">
                            <h5><img src={flag} style={{width:40, height:40, marginRight:20 }} /> App Mobile</h5>
                            <h5 className="mt-4"><img src={flag} style={{width:40, height:40, marginRight:20 }} /> Fatturazione e Pagamenti</h5>
                        </div>
                        <div className="col-md-12 col-lg-4 feature mb-3 BrinnanLight">
                            <h5><img src={flag} style={{width:40, height:40, marginRight:20 }} /> Pagamenti Personalizzati</h5>
                            <h5 className="mt-4"><img src={flag} style={{width:40, height:40, marginRight:20 }} /> Gestione Presenze</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mb-1 d-flex justify-content-center" style={{marginTop:55}}>
                            <Link to="#whyAppius" style={{ textDecoration: 'none', cursor:'pointer', borderRadius:10, backgroundColor:"rgb(140, 205, 176)" }} className="btn btn-primary BrinnanLight">
                                Scopri di pi&uacute;
                            </Link>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Feature;