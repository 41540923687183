import React from "react";

import './style.css';
import flag from "../../images/smallball.png"

const WhyAppius = () => {

    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 992) {
            setMobile(true);
        } else {
            setMobile(false);
        }
        
    }

    React.useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        handleResize();
        window.scrollTo(0, 0); 
        return () => {
            window.removeEventListener("resize", () => handleResize());
        }
    },[])
    return (
        <>
            <div id="whyAppius" className="container" style={{ marginTop: 50 }}>
                <div className="row">
                    <div className="col-lg-12 mt-5 mb-5">
                        <h1 style={{color: 'rgb(49, 48, 206)'}} className={`text-query Varela${isMobile ? " container" : ""}`}>Perch&eacute; scegliere Hdemia</h1>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-md-12 col-lg-6 why_appius">
                        <div className="d-flex">
                            <img src={flag} style={{width:40, height:40, marginRight:20}}/>
                            <div>
                                <p className="BrinnanLight" style={{fontSize:21, paddingBottom: 35, lineHeight:1.2}}>
                                    <span style={{color: 'rgb(17, 105, 104)'}}>Crea lezioni classiche e lezioni online</span> direttamente dalla piattaforma, per un utilizzo più semplice possibile.
                                </p>
                            </div>
                        </div>                        
                    </div>
                    <div className={`col-md-12 col-lg-6 why_appius d-flex flex-column${isMobile ? "" : " ps-0"}`}>
                        <div className="d-flex">
                            <img src={flag} style={{width:40, height:40, marginRight:20}}/>
                            <div>
                                <p className="BrinnanLight" style={{fontSize:21, paddingBottom: 35, lineHeight:1.2}}>
                                    Hdemia consente di gestire l'economia della tua scuola includendo il supporto per il pagamento delle <span style={{color: 'rgb(17, 105, 104)'}}>fatture degli insegnanti e tenendo sotto controllo le rate degli studenti</span>.
                                </p>
                            </div>
                        </div>
                    </div>



                    
                    <div className="row" >
                        <div className="col-md-12 col-lg-6 why_appius">
                            <div className="d-flex">
                                <img src={flag} style={{width:40, height:40, marginRight:20}}/>
                                <div>
                                    <p className="BrinnanLight" style={{fontSize:21, paddingBottom: 35, lineHeight:1.2}}>
                                        Hdemia nasce con un'<span style={{color: 'rgb(17, 105, 104)'}}>applicazione mobile Android e iOS per studenti e professori</span> che si integra con il sistema web.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 why_appius d-flex flex-column">
                            <div className="d-flex">
                                <img src={flag} style={{width:40, height:40, marginRight:20}}/>
                                <div>
                                    <p className="BrinnanLight" style={{fontSize:21, paddingBottom: 35, lineHeight:1.2}}>
                                        &Eacute; possibile definire il costo annuale e la rateizzazione per ogni singolo corso, ma Hdemia offre anche il <span style={{color: 'rgb(17, 105, 104)'}}>supporto alle rate personalizzate</span> per ogni singolo studente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-md-12 col-lg-6 why_appius">
                            <div className="d-flex">
                                <img src={flag} style={{width:40, height:40, marginRight:20}}/>
                                <div>
                                    <p className="BrinnanLight" style={{fontSize:21, paddingBottom: 35, lineHeight:1.2}}>
                                        Il <span style={{color: 'rgb(17, 105, 104)'}}>funzionamento</span> è studiato per essere il più <span style={{color: 'rgb(17, 105, 104)'}}>immediato</span> possibile e per qualsiasi domanda il nostro team è a tua disposizione.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 why_appius d-flex flex-column">
                            <div className="d-flex">
                                <img src={flag} style={{width:40, height:40, marginRight:20}}/>
                                <div>
                                    <p className="BrinnanLight" style={{fontSize:21, paddingBottom: 35, lineHeight:1.2}}>
                                        <span style={{color: 'rgb(17, 105, 104)'}}>Gestire le presenze alle lezioni non è mai stato così semplice</span>. Sia da web che da mobile sarà sufficiente fare l'appello e a tutto il resto ci penserà il sistema.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default WhyAppius;