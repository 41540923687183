import React, { useState, useEffect } from "react";
import Navbar from "../components/_App/NavbarStyleFour";
import Banner from "../components/_Solution/Banner";
import Feature from "../components/_Solution/Feature";
import Info_pt2 from "../components/_Solution/Info_pt2";
import Info from "../components/_Solution/Info";
import Footer from "../components/_App/Footer";
import { Container } from "react-bootstrap";
import WhyAppius from "../components/_Solution/WhyAppius";
import ContactUs_Solution from "../components/_Solution/ContactUs_Solution";
import { BrowserRouter } from "react-router-dom";
import GoogleManager from "../utils/GoogleManager";
import SEO from "../components/_App/seo";

const Solution = () => {
    const [hasNav, setNav] = useState(false);

    useEffect(() => {
        setNav(document !== null);
    }, []);

    return (
        <>
            <SEO title="Hdemia | APPIUS - Software House & Web Agency" />
            <GoogleManager />
            {hasNav && (
                <BrowserRouter>
                    <Navbar />
                    <Banner />
                    <Container style={{ overflow: "hidden" }}>
                        <Info />
                        <Feature />
                    </Container>
                    <Info_pt2 />
                    <WhyAppius />
                    <ContactUs_Solution />
                    <Footer />
                </BrowserRouter>
            )}
        </>
    );
};

export default Solution;
