import React from "react";
import './style.css';
import video from "../../images/hdemia.mp4"

const Info_pt2 = () => {
    return (
        <div className="position-relative overflow-hidden">  
            <video style={{ height: '100vh', width: '100%', objectFit: 'cover', zIndex: -2 }} src={video} autoPlay muted loop playsInline />  
            <div className="container" style={{position: 'absolute', top: '50%', left: '50%', zIndex:1, transform: 'translate(-50%,-50%)'}}>
                <div className="row d-flex align-items-center justify-content-center m-2" >
                    <div className="col-md-12 col-lg-6 text-query-solution text-center p-0 ">
                        <p className="GreyhoundItalic infopt2Video" style={{ color: 'rgb(49, 48, 206)', fontWeight:'bold', fontSize:'1.5em'}}>
                            <i>La soluzione per la gestione della tua scuola o accademia</i>
                        </p>
                    </div>
                    <div className="container col-md-11 col-lg-5 h-100 ms-5 p-2 d-flex flex-column justify-content-center info_pt2_text_sm">
                        <div className="row me-3">
                            <div className="col-lg-12 mt-3">
                                <h4 className="BrinnanLight" style={{ color: '#41495c' }}>Hdemia è un gestionale <span style={{color:'#116968'}}>altamente personalizzabile</span> su tua richiesta, in modo da poter soddisfare tutte le necessità della tua scuola.</h4>
                            </div>
                        </div>
                        <div className="row me-3">
                            <div className="col-lg-12 mt-3">
                                <h4 className="BrinnanLight" style={{ color: '#41495c' }}>Non importa quanti corsi il tuo istituto abbia, o quanti studenti debba gestire.
                                <span className="BrinnanLight" style={{color:'#116968'}}> Hdemia si adatta ai tuoi bisogni</span>. </h4>
                            </div>
                        </div>
                        <div className="row me-4">
                            <div className="col-lg-12 mt-3">
                                <h4 className="BrinnanLight" style={{ color: '#41495c' }}>Il pacchetto include un <span style={{color:'#116968'}}>gestionale online e un'app mobile per iOS e Android</span> che permetterà a insegnanti o studenti di usufruire del servizio.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info_pt2;