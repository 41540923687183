import React, {useEffect} from "react";

import video from '../../images/videolezione.mp4';
import './style.css';
import '../../assets/css/responsive.css';
import Logo from "../../images/icon.png"
import { IoIosArrowDown } from "react-icons/io";
import { HashLink as Link } from "react-router-hash-link";

const Banner = () => {
    
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            <div className="container-fluid p-0 m-0 overflow-hidden">
                <div className="row">
                    <div className="col-lg-12 video-height-solution" style={{height: isMobile ? '100vh' : ""}}>
                        <video className="position-relative" style={{ height: '100%', width: '100%', objectFit: 'cover'}} src={video} autoPlay muted loop playsInline />
                        <Link to="#start">
                            <div className="arrow-absolute">
                                <IoIosArrowDown className="pulse-button"></IoIosArrowDown>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div id="start" className="container pt-5">
                <div className="row d-flex align-items-center justify-content-between m-2" style={{ height: 150 }}>
                    <div className="col-md-12 col-lg-5 logo-solution d-flex justify-content-center">
                        <img src={Logo} />
                    </div>
                    <div className=" col-md-12 col-lg-7 longGoccia" style={{ marginTop: '20px' }}>
                        <h1 className="BrinnanLight" style={{fontSize: 23, marginLeft:23}}>Il software gestionale per la tua Scuola</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner;